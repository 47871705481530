import React, { FC } from "react";
import Layout from "../components/layout/Layout";
import Breadcrumb from "../components/layout/Breadcrumb";
import { Helmet } from "react-helmet";

const title = "404: Not found";

const NotFoundPage: FC = () => (
    <Layout>
        <Helmet title={title} />
        <Breadcrumb title={title} />

        <section className="section-default section-padding-100">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h1>NOT FOUND</h1>
                        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
);

export default NotFoundPage;
